<template>
  <div class="order">
    <div class="order-header">
      <div class="order-title">我的订单</div>
      <div class="order-all" @click="handleToOrderList({ type: 0 })">
        全部订单
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="order-list">
      <div class="order-list__item" v-for="item of cellsData" :key="item.key" @click="handleToOrderList(item)">
        <van-icon :name="item.icon" color="#ee0a24" />
        <span class="order-list__item-title">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'order-comp',
  data() {
    return {
      cellsData: [
        {
          key: 'pre-order',
          icon: 'records',
          title: '待预约',
          type: 1
        },
        {
          key: 'ordering',
          icon: 'orders-o',
          title: '预约中',
          type: 2
        },
        {
          key: 'has-order',
          icon: 'completed',
          title: '已预约',
          type: 3
        },
        {
          key: 'refuse-order',
          icon: 'after-sale',
          title: '退款 / 售后',
          type: 6
        },
      ]
    }
  },
  methods: {
    handleToOrderList(item) {
      this.$router.push({ name: 'orderlist', params: { type: item.type } })
    }
  }
}
</script>

<style lang="scss" scoped>
.order {
  width: 360px;
  margin: 30px auto;
  background: #fff;
  border-radius: 10px;
  padding: 15px 0;

  &-header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }

  &-title {
    padding: 0 20px 10px;
  }

  &-all {
    margin-right: 10px;
    color: #aaa;
  }
  
  &-list {
    display: flex;
    padding: 10px 0;

    &__item {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-title {
        color: #444;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}
</style>
