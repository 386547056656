<template>
  <div class="mine-info">
    <van-image
      round
      class="info-avatar"
      lazy-load
      :src="require('@/assets/user-icon.jpg')"
    />
    <div class="info-title">
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
import { SESSION_NAME, getPhone } from '../../utils/login'
export default {
  name: 'mine-info-comp',
  data() {
    return {
    }
  },
  methods: {
    handleLogin() {
      this.$router.push({ name: 'login' })
    }
  },
  computed: {
    isLogin() {
      return !!localStorage.getItem(SESSION_NAME)
    },
    title() {
      return (getPhone() || '-')
    }
  }
}
</script>

<style lang="scss" scoped>
.mine-info {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 25px 30px;
  box-shadow: 2px 2px 2px 2px #eee;
  background: linear-gradient(to bottom, #ff6034, #ee0a24);

  .info-avatar {
    width: 75px;
    height: 75px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .info-title {
    flex: 1;
    font-size: 22px;
    margin-left: 15px;
    color: #fff;
  }
}
</style>
