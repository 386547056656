<template>
  <div class="mine-page">
    <mine-info />
    <order-comp />
    <van-button
      v-if="isLogin"
      class="logout-btn"
      color="#ee0a24"
      @click="handleLogout"
    >
      退出登录
    </van-button>
  </div>
</template>

<script>
import MineInfo from '@/components/mine/mineInfo.vue'
import OrderComp from '@/components/mine/order.vue'
import { SESSION_NAME, logout } from '../utils/login'

export default {
  name: 'mine-page',
  components: {
    OrderComp,
    MineInfo
  },
  methods: {
    handleLogout() {
      logout()
      this.$router.push({ name: 'home' })
    }
  },
  computed: {
    isLogin() {
      return !!localStorage.getItem(SESSION_NAME)
    }
  }
}
</script>

<style lang="scss" scoped>
.mine-page {
  .logout-btn {
    display: block;
    width: 360px;
    margin: 50px auto 0;
  }
}
</style>